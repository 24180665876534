import React, { Fragment, useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import AOS from "aos"
import "aos/dist/aos.css"
import Logo from '@/images/new/landingPageEs/logo.svg'
import Img1 from '@/images/new/landingPageEs/sucess.png'
import Sapientia from '@/images/new/landingPageEs/sapientia.svg'
import Vertere from '@/images/new/landingPageEs/vertere.svg'
import Fractal from '@/images/new/landingPageEs/fractal.svg'

const Finalistas = ({}) => {
	useEffect(() => {
		AOS.init({
			duration : 2000
		});
	}, []);
  return (
    <Fragment>
			<section className="pageLanding" style={{ background: 'linear-gradient(79.88deg, #02F2F2 -5.36%, #028BF2 7.45%, #026FC2 22.21%, #3501AB 60.78%, #5D16FE 79.69%, #7C16FE 107.47%)', paddingTop: 46 }}>
        <div className="container">
					<div className="row" data-aos="fade-right" data-aos-once="true">
						<img src={Logo} className="MR-M-16" />
					</div>
          <div className="row">
            <div className="col-lg-12 d-none-img-mob">
              <img src={Img1} style={{ marginBottom: 32 }} />
            </div>
            <div className="col-lg-6 col-sm-12" data-aos="fade-right" data-aos-once="true">
              <div className="banner_content PT-32">
                <div className="title-header-ok"><span>Su aplicación</span> fue exitosa!</div>
                <div className="p-header-ok">Esté atento a su correo electrónico para obtener más información.</div>
							</div>
            </div>
            <div className="col-lg-6 d-none-img-desk" data-aos="fade-left" data-aos-once="true">
              <img className="img-size" src={Img1} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </section>
      <section className="linea-de-tiempo">
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div style={{ textAlign: 'center' }} data-aos="fade-right" data-aos-once="true">
                <h3 className="faq-title">Visite el siguiente enlace para ver proyectos anteriores</h3>
                <a href='/finalistas' className="faq-link">olimpiadadofuturo.com.br/finalistas</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
			<section style={{ background: '#FFFFFF', paddingBottom: 102 }} data-aos="zoom-in-down" data-aos-once="true">
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
						<div className="col-lg-2 col-sm-12 text-center MB-M-48">
							<a href="/"><img src={Sapientia} /></a>
						</div>
						<div className="col-lg-2 col-sm-12 text-center MB-M-48">
							<p className="footer-p-es">Organização:</p>
							<a href='https://institutovertere.org/' target='_blank'><img src={Vertere} /></a>
						</div>
						<div className="col-lg-2 col-sm-12 text-center">
							<p className="footer-p-es">Apoio:</p>
							<a href='https://fractaltecnologia.com.br/' target='_blank'><img src={Fractal} /></a>
						</div>
					</div>
				</div>
			</section>
    </Fragment>
  );
};

export default Finalistas;
